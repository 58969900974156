import React from "react";
import fileIconSVG from "assets/fileicon.svg";
import moment from "moment";
import { calcFileSize } from "utils/filesizelimit";

// import EmailInput from "../EmailInput";

const UnsupportedFile = ({
  file,
  setSizeLImitError,
  uploadFile,
  setFileUploadError,
  //   translatedData,
  //   setVerifyEmail,
  //   filePath,
}) => {
  return (
    <div className="flex flex-col justify-center py-16">
      <div className=" mt-3 flex flex-col items-center">
        <p className="text-black text-2xl font-semibold font-primary">
          Unsupported file format
        </p>
        <p className="text-[#2E2E2E] font-base mt-[14px] font-primary">
          Please upload a file in one of the following formats:
        </p>

        <p>PDF, TXT, DOCX, DOC, XLSX, XLS, ODS, SRT, or HTML</p>
        <div className="mt-7 flex p-4 bg-blue98 rounded-lg">
          <img src={fileIconSVG} alt="file" />
          <div className="ml-4 font-opensans">
            <p className="text-sm font-medium leading-6 text-grey26">
              {file.name}
            </p>
            <p className="text-xs	text-grey47">
              <span>{calcFileSize(file, "KB", "MB")}</span>
              <span className="mx-1">.</span>
              <span>
                {moment(file.lastModifiedDate).format("DD MMM, YYYY")}
              </span>
            </p>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-center gap-2">
          <button
            onClick={() => {
              setSizeLImitError(false);
              setFileUploadError(false);
            }}
            className="capitalize	 border border-[#5B93FF] text-[#5B93FF] px-[30px] rounded-full py-1.5"
          >
            CANCEL
          </button>
          <div className="flex">
            <label htmlFor="uploadFile" className="cursor-pointer flex">
              <div className="bg-[#5B93FF] text-white px-[30px] rounded-full py-1.5">
                Select file
              </div>
            </label>
            <input
              type="file"
              className="hidden"
              id="uploadFile"
              onChange={uploadFile}
            />
          </div>
        </div>
        {/* <p className="text-[#6E6E6E] mt-[14px] font-opensans">
          {translatedData?.help_text}{" "}
          <Link
            href={lang === "en" ? "/contact-us" : `/${lang}/contact-us`}
            className="text-[#A0009F]"
          >
            {translatedData?.file_contact_text}
          </Link>{" "}
        </p> */}
      </div>
      {/* <EmailInput
        setVerifyEmail={setVerifyEmail}
        filePath={filePath}
        translatedData={translatedData}
      /> */}
    </div>
  );
};

export default UnsupportedFile;
