export const fileExtension = (file) => {
  const unUploadedExt = [
    // "exe",
    // "bat",
    // "com",
    // "cmd",
    // "inf",
    // "ipa",
    // "osx",
    // "pif",
    // "run",
    // "wsh",
    // "mp4",
    "docx",
    "doc",
    "txt",
    "pdf",
    "csv",
    "srt",
    "xlsx",
    "xls",
    "ods",
    "html",
    "png",
    "jpeg",
    "jpg",
    "webp",
    "bmp",
    "gif",
  ];
  const extension = file.split(".").pop();
  const matchValue = unUploadedExt.find((element) => extension === element);
  return matchValue;
};
