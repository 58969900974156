export const calcFileSize = (file, kb, mb) => {
  if (!file) {
    return `0 ${kb}`;
  }
  if (file.size >= 1000000) {
    return `${(file.size / 1000000).toFixed(2)} ${mb}`;
  } else {
    return `${(file.size / 1000).toFixed(2)} ${kb}`;
  }
};
